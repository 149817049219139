import React, { useState } from 'react'
import { Link } from 'gatsby'
import BEMHelper from 'react-bem-helper'
import { useNavLinks } from '../../hooks/useNavLinks'

import './Nav.scss'

const bem = new BEMHelper('nav')

const NavItem = ({ modifier, to, text, whiteText, disabled = false }) => {
  const [isHoveringDisabled, setIsHoveringDisabled] = useState(false)

  const handleMouseOver = () => setIsHoveringDisabled(true)
  const handleMouseOut = () => setIsHoveringDisabled(false)

  if (disabled) {
    return (
      <div
        {...bem(`link`, isHoveringDisabled ? 'italic' : '')}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {isHoveringDisabled ? 'coming soon' : text}{' '}
      </div>
    )
  }

  return (
    <Link {...bem(`link`, modifier, whiteText)} to={to} disabled={true}>
      {text}
    </Link>
  )
}

const Nav = ({ type = '', pathname }) => {
  const [linkLeft, linkRight] = useNavLinks(pathname)

  return (
    <nav {...bem(null, type)}>
      <ul {...bem('list')}>
        <li {...bem('item')}>
          <NavItem
            modifier={linkLeft.modifier}
            to={linkLeft.to}
            text={linkLeft.text}
            whiteText={linkLeft.whiteText}
          />
        </li>
        <li {...bem('item')}>
          <NavItem
            modifier={linkRight.modifier}
            to={linkRight.to}
            text={linkRight.text}
            disabled={true}
          />
        </li>
      </ul>
    </nav>
  )
}

export default Nav

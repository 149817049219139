exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-exhibitions-js": () => import("./../../../src/pages/projects/exhibitions.js" /* webpackChunkName: "component---src-pages-projects-exhibitions-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-publications-js": () => import("./../../../src/pages/projects/publications.js" /* webpackChunkName: "component---src-pages-projects-publications-js" */),
  "component---src-pages-projects-workshops-js": () => import("./../../../src/pages/projects/workshops.js" /* webpackChunkName: "component---src-pages-projects-workshops-js" */),
  "component---src-templates-artist-artist-js": () => import("./../../../src/templates/Artist/Artist.js" /* webpackChunkName: "component---src-templates-artist-artist-js" */)
}


import { useContext } from 'react'
import { ThemeContext } from '../components/Layout/Layout'

const HOME_PATH = '/'
const ARTISTS_PATH = '/artists'
const PROJECTS_PATH = '/projects'

const HOME_TEXT = 'jergon'
const ARTISTS_TEXT = 'artists'
const PROJECTS_TEXT = 'projects'

export const useNavLinks = (pathname) => {
  const { whiteText } = useContext(ThemeContext)

  const isHomePage = pathname === HOME_PATH
  const isProjectsPage = pathname?.includes(PROJECTS_PATH)

  const linkLeft = {
    to: isHomePage ? ARTISTS_PATH : HOME_PATH,
    text: isHomePage ? ARTISTS_TEXT : HOME_TEXT,
    modifier: isHomePage ? '' : 'italic',
    whiteText: isHomePage && whiteText ? 'white-text' : '',
  }

  const linkRight = {
    to: isProjectsPage ? ARTISTS_PATH : ARTISTS_PATH,
    text: isProjectsPage ? ARTISTS_TEXT : PROJECTS_TEXT,
  }

  return [linkLeft, linkRight]
}

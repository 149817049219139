import React, { useState, useEffect } from 'react'
import BEMHelper from 'react-bem-helper'
import 'normalize.css'
import useWindowSize from '../../hooks/useWindowSize'
import Nav from '../Nav'

import './Layout.scss'

const bem = new BEMHelper('layout')

export const ThemeContext = React.createContext({
  whiteText: false,
  setWhiteText: () => {},
})

const Layout = ({ children, location }) => {
  const { pathname } = location
  const height = useWindowSize()[1]

  const [whiteText, setWhiteText] = useState(false)
  const value = { whiteText, setWhiteText }

  useEffect(() => {
    document.documentElement.style.setProperty('--vh', `${height * 0.01}px`)
  }, [height])

  useEffect(() => {
    const storedArtistId = sessionStorage.getItem('artistId')
    if (storedArtistId && location.pathname === '/') {
      sessionStorage.removeItem('artistId')
    }
  }, [location])

  return (
    <ThemeContext.Provider value={value}>
      <div {...bem()}>
        <Nav type="main" pathname={pathname} />
        <main {...bem('main')}>{children}</main>
      </div>
    </ThemeContext.Provider>
  )
}

export default Layout
